$( document ).ready(function() {
    $(document).keydown(function(event) {
        if ( event.which == 76 || event.which == 112) {
            //console.log('toggle login form');
            if ( $( "input" ).is(":focus")) {
                //console.log('Input focus');
            } else {
                event.preventDefault();
                $( ".participantLogin, .staffLogin" ).toggle();
            }
        }
    });

    if($(".staffLogin .form-group").hasClass("has-error")) {
        $(".staffLogin").show();
        $(".participantLogin").hide();
    }
});
